<template>
  <v-container fluid>
    <BasePageTitle>View Quotes</BasePageTitle>
    <div class="mt-2">
      <v-row class="mx-1">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <BaseRefreshButton color="secondary" @nextAction="updateQuoteList" />
          <span v-if="isBackgroundLoading" class="title warning--text"
            >Loading additional data in the background...</span
          >
        </v-col>
      </v-row>
      <v-card flat class="ma-3">
        <v-tabs
          v-model="tab"
          background-color="primary"
          fixed-tabs
          dark
          icons-and-text
        >
          <v-tabs-slider color="amber"></v-tabs-slider>

          <v-tab
            v-for="(quoteStat, index) in quoteStatus"
            :key="index"
            @click="updateActiveTab(quoteStat.description)"
            class="text-capitalize"
          >
            <v-badge
              v-if="breakDown[quoteStat.description] > 0"
              light
              color="warning"
              :content="breakDown[quoteStat.description]"
            >
              <span class="text-capitalize">{{ quoteStat.description }}</span>
            </v-badge>
            <span v-else class="text-capitalize">
              {{ quoteStat.description }}
            </span>
          </v-tab>
        </v-tabs>
        <v-progress-linear
          v-if="isBackgroundLoading"
          indeterminate
          rounded
          height="5"
          color="warning"
          class="mt-1"
        ></v-progress-linear>
        <v-sheet v-if="isAdmin" outlined class="my-5 pa-5">
          <v-toolbar color="warning" dark dense>
            <v-toolbar-title>Admin Only - Search all Quotes</v-toolbar-title>
          </v-toolbar>
          <v-row dense>
            <v-col cols="12" md="4">
              <BaseSearchProjectTextField
                ref="search-project"
                label="Search by Project Name"
                dataType="project"
                dataKey="search"
                :isSelectDisabled="
                  !!selectedUser || !!selectedCompany
                "
                @clearFilters="clearFilters"
                @filterQuotes="filterQuotes"
              />
            </v-col>
            <v-col cols="12" md="4">
              <BaseSearchUserSelect
                ref="search-user"
                label="Search User"
                :items="users"
                :itemText="`email`"
                :itemValue="`pk`"
                :isSelectDisabled="
                  !!selectedProject || !!selectedCompany
                "
                :allowMultiple="false"
                :loading="usersLoading"
                dataType="user"
                dataKey="user_id"
                key="user"
                @clearFilters="clearFilters('search-user')"
                @filterQuotes="filterQuotes"
                @loadData="onLoadData($event, `users`)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <BaseSearchUserSelect
                ref="search-company"
                label="Search Company"
                :items="companies"
                :itemText="`name`"
                :itemValue="`id`"
                :isSelectDisabled="
                  !!selectedProject || !!selectedUser
                "
                :allowMultiple="false"
                :loading="companiesLoading"
                dataType="company"
                dataKey="company_id"
                key="company"
                @clearFilters="clearFilters('search-company')"
                @filterQuotes="filterQuotes"
                @loadData="onLoadData($event, `companies`)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="listAllQuotes"
                label="View All Quotes"
                color="warning"
                dense
                hide-details
                class="mx-2 admin-label"
                @change="updateQuoteList"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-else v-show="arrayList.length > 0" outlined class="pa-5">
          <v-toolbar color="secondary" dark dense>
            <v-toolbar-title>{{ isAdmin ? "Filter Quotes" : "Search My Quotes" }}</v-toolbar-title>
          </v-toolbar>
           <v-row dense>
            <v-col cols="12" md="4">
              <BaseSearchProjectTextField
                label="Search Quotes by Project Name"
                dataType="project"
                dataKey="search"
                @clearFilters="clearFilters"
                @filterQuotes="filterQuotes"
              />
            </v-col>
           </v-row>
        </v-sheet>
        <v-tabs-items v-model="tab" class="no-transition">
          <v-tab-item
            v-for="(quoteStat, index) in quoteStatus"
            :key="index"
            transition="false"
          >
            <v-card
              v-if="arrayList.length > 0"
              flat
              class="mt-2"
              :class="!isAdmin ? 'mt-2' : ''"
            >
              <v-row dense class="d-xl-none pa-0 ma-0">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <span class="warning--text subtitle-2"
                    >Narrow screen, hiding columns: [ DUT Names, Created At,
                    Status ]</span
                  >
                </v-col>
              </v-row>
              <v-row dense class="d-xl-none pa-0 ma-0">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <span class="warning--text subtitle-2 font-weight-bold"
                    >Widen screen or collapse side navigation bar to view all
                    columns.</span
                  >
                </v-col>
              </v-row>
              <v-row dense class="d-xl-none pa-0 ma-0">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="showAllColumns"
                    :label="
                      showAllColumns
                        ? 'Showing All Columns'
                        : 'Show All Columns'
                    "
                    color="secondary"
                    dense
                    hide-details
                    class="subtitle-2"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-card-text>
                <v-data-table
                  v-model="selection"
                  :headers="getHeaders"
                  :items="arrayList"
                  :value="arrayList"
                  :search="searchProjectString"
                  :sort-by="['created']"
                  sort-desc
                  :items-per-page="ITEMS_PER_PAGE"
                  item-key="pk"
                  class="mb-5"
                  :item-class="setRowStyle"
                  :footer-props="pageOptions"
                  :data-cy="`quotes-list-grid-${index}`"
                >
                  <template v-slot:[`item.id`]="props">
                    <span
                      class="text-capitalize"
                      :data-cy="`quotes-list-id-column-${index}-${props.item.id}`"
                      >{{ props.item.id }}</span
                    >
                  </template>
                  <template v-slot:[`item.user_name`]="props">
                    <v-row dense>
                      <span>{{ props.item.user_name }}</span>
                    </v-row>
                    <v-row dense>
                      <span>{{ props.item.user_email }}</span>
                    </v-row>
                  </template>
                  <template v-slot:[`item.company_name`]="props">
                    <v-row dense>
                      <CompanyDetailsModal
                        :displayString="
                          props.item.company_name
                            ? props.item.company_name
                            : props.item.company_request
                        "
                        :billingName="
                          props.item.billing_name
                            ? props.item.billing_name
                            : props.item.full_name
                        "
                        :billingCompany="
                          props.item.billing_company
                            ? props.item.billing_company
                            : null
                        "
                        :billingStreet1="
                          props.item.billing_street1
                            ? props.item.billing_street1
                            : props.item.billing_address_request
                        "
                        :billingStreet2="
                          props.item.billing_street2
                            ? props.item.billing_street2
                            : null
                        "
                        :billingAddress2="
                          props.item.billing_address2
                            ? props.item.billing_address2
                            : null
                        "
                        :billingEmail="
                          props.item.billing_email
                            ? props.item.billing_email
                            : null
                        "
                        :billingPhone="
                          props.item.billing_phone
                            ? props.item.billing_phone
                            : null
                        "
                        :shippingName="
                          props.item.shipping_name
                            ? props.item.shipping_name
                            : props.item.full_name
                        "
                        :shippingCompany="
                          props.item.shipping_company
                            ? props.item.shipping_company
                            : null
                        "
                        :shippingStreet1="
                          props.item.shipping_street1
                            ? props.item.shipping_street1
                            : props.item.shipping_address_request
                        "
                        :shippingStreet2="
                          props.item.shipping_stree2
                            ? props.item.shipping_street2
                            : null
                        "
                        :shippingAddress2="
                          props.item.shipping_address2
                            ? props.item.shipping_address2
                            : null
                        "
                        :shippingEmail="
                          props.item.shipping_email
                            ? props.item.shipping_email
                            : null
                        "
                        :shippingPhone="
                          props.item.shipping_phone
                            ? props.item.shipping_phone
                            : null
                        "
                        :shippingSameAsBilling="
                          props.item.billing_address
                            ? props.item.shipping_same_as_billing
                            : props.item.shipping_same_as_billing_request
                        "
                        :tabIndex="index"
                        :quoteId="props.item.id"
                        :billingAddressId="props.item.billing_address"
                        :shippingAddressId="props.item.shipping_address"
                      />
                    </v-row>
                  </template>
                  <template v-slot:[`item.dut_names`]="props">
                    <v-row
                      dense
                      v-for="(name, index2) in props.item.dut_names"
                      :key="index2"
                    >
                      <v-chip small class="mt-1">{{ name }}</v-chip>
                    </v-row>
                  </template>
                  <template v-slot:[`item.full_part_number`]="props">
                    <template>
                      <span>
                        <v-chip
                          v-if="props.item.full_part_number"
                          color="secondary"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                left
                                v-on="on"
                                class="ml-1 mr-3"
                                :disabled="!props.item.dut_id"
                                @click="
                                  gotoConfigure({
                                    projectId: props.item.project_id,
                                    dutId: props.item.dut_id,
                                    newTab: false,
                                  })
                                "
                                @contextmenu="
                                  activateMenu({
                                    nextRoute: {
                                      projectId: props.item.project_id,
                                      dutId: props.item.dut_id,
                                      newTab: true,
                                    },
                                    nextAction: 'gotoConfigure',
                                    event: $event,
                                  })
                                "
                              >
                                mdi-cog
                              </v-icon>
                            </template>
                            <span>
                              Test Points, Guide Pins, and Pressure Pins
                              Configuration
                            </span>
                          </v-tooltip>
                          {{ `${props.item.full_part_number}` }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                right
                                small
                                v-on="on"
                                class="ml-3 mr-1"
                                :disabled="!props.item.full_part_number"
                                @click.stop="
                                  showDialog({
                                    id: {
                                      fdId: props.item.fixture_design,
                                      quoteId: props.item.id,
                                    },
                                    nextAction: 'unlink',
                                    message:
                                      'Are you sure you\'d like to unlink this Quote?',
                                  })
                                "
                              >
                                mdi-close
                              </v-icon></template
                            >
                            <span>
                              Unlink Quote To Project
                            </span>
                          </v-tooltip>
                        </v-chip>
                        <v-chip v-else @click="onSearchProject(props.item.id)"
                          >Link to Project</v-chip
                        >
                      </span>
                    </template>
                  </template>
                  <template v-slot:[`item.created`]="props">
                    {{ formatDate(props.item.created) }}
                  </template>
                  <template v-slot:[`item.status`]="props">
                    <span
                      class="text-capitalize"
                      :data-cy="`quotes-list-status-column-${index}-${props.item.id}`"
                      >{{ props.item.status }}</span
                    >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-icon
                            color="secondary"
                            small
                            class="mx-1"
                            @click.stop="quoteDetailsView(item.id)"
                            :data-cy="`quotes-list-view-button-${item.id}`"
                          >
                            mdi-eye
                          </v-icon>
                        </span>
                      </template>
                      <span>View Quote Details</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-icon
                            color="secondary"
                            small
                            class="mx-1"
                            :disabled="
                              item.status.toLowerCase() != EDITABLE_STATUS &&
                                !isAdmin
                            "
                            @click="
                              onUpdateQuote({
                                quoteId: item.id,
                                activeTab: DEFAULT_TAB.id,
                                newTab: false,
                              })
                            "
                            @contextmenu="
                              activateMenu({
                                nextRoute: {
                                  quoteId: item.id,
                                  activeTab: DEFAULT_TAB.id,
                                  newTab: true,
                                },
                                nextAction: 'onUpdateQuote',
                                event: $event,
                              })
                            "
                            :data-cy="`quotes-list-edit-button-${item.id}`"
                          >
                            mdi-pencil
                          </v-icon>
                        </span>
                      </template>
                      <span>{{
                        item.status.toLowerCase() != EDITABLE_STATUS && !isAdmin
                          ? "FixturFab is currently building an estimate. Email sales@fixturfab.com if you need to modify this quote."
                          : "Edit Quote"
                      }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-icon
                            color="warning"
                            small
                            class="mx-1"
                            @click.stop="
                              showDialog({
                                id: item.id,
                                nextAction: 'delete',
                                message:
                                  'Are you sure you\'d like to delete this quote? You will have to restart the process for any new quotes.',
                              })
                            "
                            :data-cy="`quotes-list-delete-button-${index}-${item.id}`"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </template>
                      <span>Delete Quote</span>
                    </v-tooltip>
                    <v-tooltip top v-if="isAdmin">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-icon
                            color="warning"
                            small
                            class="mx-1"
                            :disabled="
                              item.status.toLowerCase() == EDITABLE_STATUS
                            "
                            @click.stop="
                              showDialog({
                                id: item.id,
                                nextAction: 'reset',
                                message:
                                  'Are you sure you\'d like to set this Quote status to incomplete? Non-admins will be able to edit it again.',
                              })
                            "
                            :data-cy="`quotes-list-reset-button-${item.id}`"
                          >
                            mdi-lock-reset
                          </v-icon>
                        </span>
                      </template>
                      <span>Set to Incomplete</span>
                    </v-tooltip>
                    <QuoteStatusModal
                      v-if="isAdmin"
                      :quote="item"
                      :quoteStatus="quoteStatus"
                      @onUpdateQuoteStatus="patchQuoteStatus"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else class="my-5" flat>
              <v-card-text class="body-2 font-weight-bold text-center"
                >No quotes to display</v-card-text
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <BaseBottomDialog
        v-if="bottomDialog"
        :dialog="bottomDialog"
        :message="confirmMessage"
        :action="confirmAction"
        @delete="onDeleteQuote"
        @reset="updateQuoteStatus"
        @saveFixture="onSaveFixture"
        @unlink="onUnlinkFixture"
        @unlinkModal="onUnlinkModalFixture"
        @donothing="stopDialog"
      />
      <BaseSubMenuPopup
        :showMenu="showMenu"
        :xCoord="xCoord"
        :yCoord="yCoord"
        :nextAction="nextAction"
        @onClick="subMenuClickHandler"
        @onCopyUrl="subMenuCopyUrl"
      />
    </div>
    <SearchProjectModal
      v-if="projectDialog"
      ref="projectModal"
      :dialog="projectDialog"
      :quoteId="quoteId"
      @saveFixture="showFixtureDialog"
      @closeProjectModal="onCloseProjectModal"
    />
    <QuoteDetailsModal
      :dialog="quoteDetailsDialog"
      :quote="quote"
      :dutDetails="dutDetails"
      :projectType="projectType"
      :projectTimeline="projectTimeline"
      :fixtureWiring="fixtureWiring"
      :dutConfigs="dutConfigs"
      :isAdmin="isAdmin"
      @goToFixtureDesign="onGoToFixtureDesign"
      @gotoUpdateQuote="onUpdateQuote"
      @onDownloadFile="downloadFile"
      @onActivateMenu="activateMenu"
      @onUnlinkQuote="unlinkQuoteToFD"
      @onGoToFixtureDesignTable="gotoFixtureDesignTable"
      @closeQuoteDetailsModal="onCloseQuoteDetailsModal"
    />
    <FixtureDesignModal
      :fddialog="fdDetailsDialog"
      :fixtureDesign="fixtureDesign"
      :selectedProject="selProject"
      :fdState="fdState"
      :fdStateLongList="fdStateLongList"
      @gotoQuoteModal="quoteDetailsView"
      @onGoToFulfillment="gotoFulfillment"
      @onActivateMenu="activateMenu"
      @closeFDDetailsModal="onCloseFDDetailsModal"
    />
  </v-container>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import SelectDataLoader from "@/mixins/SelectDataLoader";
import PageOptions from "@/mixins/PageOptions";
import SubMenuPopup from "@/mixins/SubMenuPopup";
import { fileDownloader } from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "QuotesIndex",
  mixins: [BottomDialog, SelectDataLoader, PageOptions, SubMenuPopup],
  props: {
    activeTab: {
      type: Object,
      default: null,
    },
  },
  components: {
    SearchProjectModal: () => import("../components/SearchProjectModal.vue"),
    QuoteDetailsModal: () => import("../components/QuoteDetailsModal.vue"),
    FixtureDesignModal: () => import("../components/FixtureDesignModal.vue"),
    QuoteStatusModal: () => import("../components/QuoteStatusModal.vue"),
    CompanyDetailsModal: () => import("../components/CompanyDetailsModal.vue"),
  },
  data() {
    return {
      tab: null,
      active_tab: null,
      selection: [],
      ITEMS_PER_PAGE: 20,
      DEFAULT_TAB: {
        id: 0,
        name: "All",
      },
      EDITABLE_STATUS: "incomplete",
      listAllQuotes: false,
      projectDialog: false,
      quoteId: null,
      fdId: null,
      selectedProject: null,
      selectedUser: null,
      selectedCompany: null,
      quoteDetailsDialog: false,
      fdDetailsDialog: false,
      searchProjectString: "",
      DEFAULT_PCB_THICKNESS: 1.6,
      showAllColumns: false,
    };
  },
  computed: {
    ...mapGetters({
      quotes: "quotes/quotes",
      quoteStatus: "quotes/quoteStatus",
      projectType: "quotes/projectType",
      projectTimeline: "quotes/projectTimeline",
      fixtureWiring: "quotes/fixtureWiring",
      quote: "quotes/quote",
      dutDetails: "quotes/dutDetailsView",
      dutConfigs: "quotes/dutConfigs",
      addresses: "quotes/addresses",
      company: "projects/company",
      user: "projects/projectUser",
      isAdmin: "auth/isAdmin",
      fixtureDesign: "fixturedesign/fixtureDesign",
      selProject: "projects/selProject",
      fdState: "fixturedesign/fdState",
      fdStateLongList: "ui/fdStateLongList",
      isBackgroundLoading: "ui/backgroundLoading",
    }),
    mappedQuotes() {
      return this.quotes
        ? this.quotes.map((quote) => {
            const typeFound = this.projectType.find(
              (ptype) => ptype.pk == quote.project_type
            );
            const billingFound = this.addresses.find(
              (address) => address.id == quote.billing_address
            );
            const shippingFound = this.addresses.find(
              (address) => address.id == quote.shipping_address
            );
            return {
              ...quote,
              company_name:
                Object.keys(quote.company_details).length > 0
                  ? quote.company_details.name
                  : null,
              billing_company: billingFound
                ? billingFound.company_name
                : null,
              billing_name: billingFound
                ? billingFound.name
                : Object.keys(quote.user_details).length > 0
                ? quote.user_details.full_name
                : null,
              billing_street1: billingFound
                ? billingFound.street1
                : null,
              billing_street2: billingFound
                ? billingFound.street2
                : null,
              billing_address2: billingFound
                ? `${billingFound.city}, ${billingFound.state}, ${billingFound.zip}`
                : null,
              billing_email: billingFound
                ? billingFound.email
                : null,
              billing_phone: billingFound
                ? billingFound.phone
                : null,
              shipping_same_as_billing: quote.shipping_same_as_billing,
              shipping_name: shippingFound
                ? shippingFound.name
                : Object.keys(quote.user_details).length > 0
                ? quote.user_details.full_name
                : null,
              shipping_company: shippingFound
                ? shippingFound.company_name
                : null,
              shipping_street1: shippingFound
                ? shippingFound.street1
                : null,
              shipping_street2: shippingFound
                ? shippingFound.street2
                : null,
              shipping_address2: shippingFound
                ? `${shippingFound.city} ${shippingFound.state} ${shippingFound.zip}`
                : null,
              shipping_email: shippingFound
                ? shippingFound.email
                : null,
              shipping_phone: shippingFound
                ? shippingFound.phone
                : null,
              full_name:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.username
                  : null,
              user_name:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.username
                  : null,
              user_email:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.email
                  : null,
              company_request:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.company_request
                  : null,
              billing_address_request:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.billing_address_request
                  : null,
              shipping_same_as_billing_request:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.shipping_same_as_billing_request
                  : null,
              shipping_address_request:
                Object.keys(quote.user_details).length > 0
                  ? quote.user_details.shipping_address_request
                  : null,
              project_type_name: typeFound ? typeFound.description : null,
              dut_names: Object.values(quote.dut_details),
              fixture_design: Object.keys(quote.fixture_design_details)[0],
              full_part_number: Object.values(quote.fixture_design_details)[0]
                ?.full_part_number,
              fd_project_name: Object.values(quote.fixture_design_details)[0]
                ?.project_name,
              dut_id: Object.values(quote.fixture_design_details)[0]
                ?.dut_configs[0],
              project_id: Object.values(quote.fixture_design_details)[0]
                ?.project_pk,
            };
          })
        : [];
    },
    arrayList() {
      return this.active_tab == "All"
        ? this.mappedQuotes.filter(
            (element) => !element.added_from_create_project_view
          )
        : this.mappedQuotes.filter(
            (element) =>
              element.status == this.active_tab &&
              !element.added_from_create_project_view
          );
    },
    breakDown() {
      const counts = {
        All: this.mappedQuotes.filter(
          (element) => !element.added_from_create_project_view
        ).length,
      };
      this.mappedQuotes
        .filter((element) => !element.added_from_create_project_view)
        .forEach((quote) => {
          counts[quote.status] = (counts[quote.status] || 0) + 1;
        });
      return counts;
    },
    getHeaders() {
      return this.isAdmin
        ? this.headers
        : this.headers.filter((element) => element.text !== "Fixture Design");
    },
    savedDutConfigs() {
      return Object.keys(this.quote.fixture_design_details).length > 0
        ? Object.values(this.quote.fixture_design_details)[0]?.dut_configs
        : null;
    },
    alignString() {
      return this.showAllColumns ? "start" : " d-none d-xl-table-cell";
    },
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "User Name",
          align: "start",
          value: "user_name",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Company Name",
          align: "start",
          value: "company_name",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Project Name",
          align: "start",
          value: "project_name",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "DUT Names",
          align: this.alignString,
          value: "dut_names",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Project Type",
          align: " d-none d-lg-table-cell",
          value: "project_type_name",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Fixture Design",
          align: "start",
          value: "full_part_number",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Created At",
          align: this.alignString,
          value: "created",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Status",
          align: this.alignString,
          value: "status",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
          class: "primary--text body-2 font-weight-bold",
        },
      ];
    },
  },
  methods: {
    fileDownloader,
    ...mapActions({
      fetchQuotes: "quotes/getQuotesByUser",
      fetchStatus: "quotes/getQuoteStatus",
      fetchAllQuotes: "quotes/getQuotes",
      removeQuote: "quotes/deleteQuote",
      saveQuote: "quotes/saveQuote",
      searchQuotes: "quotes/searchQuotes",
      fetchQuote: "quotes/getQuote",
      fetchDutDetails: "quotes/getDutDetailsView",
      fetchDutConfigs: "quotes/getDutConfigsById",
      updateDutConfigs: "quotes/updateDutConfigs",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      createDutConfig: "dutconfigs/saveDutConfigs",
      toggleLoading: "ui/loading",
      updateRedirect: "ui/updateRedirectFullpath",
    }),
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    rowColor(index) {
      return index % 2 !== 0 ? "#F5F5F5" : "white";
    },
    updateActiveTab(activeText) {
      this.active_tab = activeText;
    },
    gotoFulfillment({ fdId, newTab = false }) {
      if (newTab) {
        let routeData = this.$router.resolve({
          name: "fulfillment",
          params: { id: fdId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "fulfillment",
          params: { id: fdId },
        });
      }
    },
    onUpdateQuote({ quoteId, activeTab, newTab = false }) {
      if (newTab) {
        localStorage.setItem(
          "quote",
          JSON.stringify({ active_tab: activeTab })
        );
        let routeData = this.$router.resolve({
          name: "quote-edit",
          params: { id: quoteId, activeTab: 1 },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "quote-edit",
          params: { id: quoteId, activeTab },
        });
      }
    },
    async onDeleteQuote() {
      try {
        await this.removeQuote(this.selected);
        this.confirmMessage = this.confirmAction = this.selected = null;
        this.stopDialog();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async updateQuoteList() {
      if (this.listAllQuotes) {
        this.selectedProject && this.updateSearchTextField({
          refname: "search-project",
          payload: null,
        });
        this.selectedUser && this.updateSearchTextField({
          refname: "search-user",
          payload: null,
        });
        this.selectedCompany && this.updateSearchTextField({
          refname: "search-company",
          payload: null,
        });
        this.selectedProject = this.selectedUser = this.selectedCompany = null;
        await this.getAllQuotes();
      } else {
        await this.getQuotesByUser();
      }
    },
    async getAllQuotes() {
      try {
        await this.fetchAllQuotes();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async getQuotesByUser() {
      try {
        await this.fetchQuotes({
          params: { user_id: this.$store.state.auth.user.pk },
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    gotoConfigure({ projectId, dutId, newTab = false }) {
      if (newTab) {
        let routeData = this.$router.resolve({
          name: "configure",
          params: { id: projectId, did: dutId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "configure",
          params: { id: projectId, did: dutId },
        });
      }
    },
    async gotoConfigureCopyUrl({ projectId, dutId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: "configure",
          params: { id: projectId, did: dutId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    async onUpdateQuoteCopyUrl({ quoteId, activeTab }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: "quote-edit",
          params: { id: quoteId, activeTab },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    async gotoFulfillmentCopyUrl({ fdId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: "fulfillment",
          params: { id: fdId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    async updateQuoteStatus() {
      const actionPayload = {
        editMode: true,
        id: this.selected,
        data: {
          status: this.EDITABLE_STATUS,
        },
      };
      this.patchQuoteStatus(actionPayload);
      this.confirmMessage = this.confirmAction = this.selected = null;
      this.stopDialog();
    },
    async patchQuoteStatus(payload) {
      try {
        this.toggleLoading("Updating Quote");
        const response = await this.saveQuote(payload);
        this.$store.commit("quotes/UPDATE_QUOTE_LIST", response);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    setRowStyle() {
      return "taller-table-style";
    },
    onSearchProject(id) {
      this.quoteId = id;
      this.projectDialog = true;
    },
    showFixtureDialog({ quote, fixtureDesign, projectName }) {
      this.confirmAction = "saveFixture";
      this.confirmMessage = `Are you sure to assign Quote ${quote} to project ${projectName}?`;
      this.quoteId = quote;
      this.fdId = fixtureDesign;
      this.bottomDialog = true;
    },
    async onSaveFixture() {
      this.bottomDialog = this.projectDialog = false;
      let formData = new FormData();
      formData.append("quote", this.quoteId);
      try {
        await this.patchFixtureDesign({ pk: this.fdId, data: formData });
        await this.updateQuoteList();
        await this.saveDdToDc(this.quoteId);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.quoteId = this.fdId = null;
    },
    async saveDdToDc(quoteId) {
      let quoteToLink = this.quotes.find((element) => element.id == quoteId);
      if (!quoteToLink || Object.keys(quoteToLink.dut_details).length == 0) {
        console.log("Quote is not found from the list");
        return;
      }
      if (
        Object.keys(quoteToLink.dut_details).length >
        Object.values(quoteToLink.fixture_design_details)[0]?.dut_configs.length
      ) {
        console.log("DD length is > DC length");
        for (
          let i = Object.values(quoteToLink.fixture_design_details)[0]
            ?.dut_configs.length;
          i < Object.keys(quoteToLink.dut_details).length;
          i++
        ) {
          await this.addNewDC({
            user: quoteToLink.user,
            project: Object.values(quoteToLink.fixture_design_details)[0]
              ?.project_pk,
            fixture_design: Object.keys(quoteToLink.fixture_design_details)[0],
            dut_name: Object.values(quoteToLink.dut_details)[i],
            dut_details: Object.keys(quoteToLink.dut_details)[i],
          });
        }
        await this.updateQuoteList();
        quoteToLink = this.quotes.find((element) => element.id == quoteId);
      }
      for (let i = 0; i < Object.keys(quoteToLink.dut_details).length; i++) {
        let ddToDcData = new FormData();
        ddToDcData.append(
          "dut_details",
          Object.keys(quoteToLink.dut_details)[i]
        );
        this.toggleLoading("One moment please, updating DUT Config");
        await this.updateDutConfigs({
          pk: Object.values(quoteToLink.fixture_design_details)[0]?.dut_configs[
            i
          ],
          data: ddToDcData,
        });
        this.toggleLoading();
        console.log("Created Link DD to DC");
      }
      await this.updateQuoteList();
    },
    async addNewDC({ user, project, fixture_design, dut_name, dut_details }) {
      console.log("Added new DC");
      let dutData = new FormData();
      dutData.append("name", dut_name);
      dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
      dutData.append("user", user);
      dutData.append("project", project);
      dutData.append("fixture_design", fixture_design);
      dutData.append("dut_details", dut_details);
      await this.createDutConfig(dutData);
    },
    async onUnlinkFixture() {
      const { fdId, quoteId } = this.selected;
      this.stopDialog();
      let formData = new FormData();
      formData.append("quote", "");
      try {
        await this.patchFixtureDesign({ pk: fdId, data: formData });
        this.toggleLoading("Removing link from DUT Configs");
        await this.unlinkDC(quoteId);
        this.toggleLoading();
        await this.updateQuoteList();
        this.selected = null;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onUnlinkModalFixture() {
      this.stopDialog();
      let formData = new FormData();
      formData.append("quote", "");
      try {
        await this.patchFixtureDesign({ pk: this.selected, data: formData });
        this.toggleLoading("Updating render");
        await this.unlinkDC(this.quoteId);
        await Promise.all([
          this.updateQuoteList(),
          this.fetchQuote(this.quoteId),
        ]);
        this.selected = this.quoteId = null;
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async unlinkDC(quoteId) {
      let quoteToLink = this.quotes.find((element) => element.id == quoteId);
      if (!quoteToLink) {
        console.log("Quote is not found from the list");
        return;
      }
      if (
        Object.keys(quoteToLink.fixture_design_details).length > 0 &&
        Object.values(quoteToLink.fixture_design_details)[0]?.dut_configs
          .length > 0
      ) {
        for (
          let i = 0;
          i <
          Object.values(quoteToLink.fixture_design_details)[0]?.dut_configs
            .length;
          i++
        ) {
          let dcData = new FormData();
          dcData.append("dut_details", "");
          await this.updateDutConfigs({
            pk: Object.values(quoteToLink.fixture_design_details)[0]
              ?.dut_configs[i],
            data: dcData,
          });
        }
      }
    },
    onCloseProjectModal() {
      this.projectDialog = false;
    },
    async filterQuotes({ type, params }) {
      try {
        if (type == "project") {
          this.selectedProject = params.search;
          this.selectedUser = this.selectedCompany = null;
        } else if (type == "user") {
          this.selectedUser = params.user_id;
          this.selectedProject = this.selectedCompany = null;
        } else {
          this.selectedCompany = params.company_id;
          this.selectedProject = this.selectedUser = null;
        }
        this.listAllQuotes = false;
        await this.searchQuotes({
          params,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    clearFilters(componentRefs = null) {
      if (this.selectedProject || this.selectedUser || this.selectedCompany)
        this.getQuotesByUser();
      this.selectedProject = this.selectedUser = this.selectedCompany = null;
      componentRefs &&
        this.updateSearchTextField({
          refname: componentRefs,
          payload: null,
        });
    },
    async quoteDetailsView(pk) {
      if (this.fdDetailsDialog) this.fdDetailsDialog = false;
      try {
        this.toggleLoading("Loading Quote Data");
        this.addHashToLocation(`?quote_id=${pk}`);
        console.log(`${this.$route.path}?quote_id=${pk}`);
        this.updateRedirect(`${this.$route.path}?quote_id=${pk}`);
        await this.fetchQuote(pk);
        await this.fetchDutDetails({ params: { quote_id: pk } });
        this.quoteDetailsDialog = true;
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    addHashToLocation(params) {
      history.replaceState({}, null, this.$route.path + params);
    },
    onCloseQuoteDetailsModal() {
      this.quoteDetailsDialog = false;
      this.addHashToLocation("");
      console.log(this.$route.path);
      this.updateRedirect(this.$route.path);
    },
    async downloadFile({ url, filename }) {
      const actionPayload = {
        url,
        file: filename,
      };
      this.toggleLoading("Downloading, one moment please");
      await this.fileDownloader(actionPayload);
      this.toggleLoading();
    },
    async onGoToFixtureDesign(payload) {
      if (this.quoteDetailsDialog) this.quoteDetailsDialog = false;
      if (
        Object.keys(this.fixtureDesign).length > 0 &&
        this.fdState.length > 0 &&
        this.fdStateLongList.length > 0 &&
        Object.keys(this.selProject).length > 0 &&
        this.fixtureDesign?.project == payload
      ) {
        this.fdDetailsDialog = true;
        this.addHashToLocation(`?project_id=${payload}`);
        this.updateRedirect(`${this.$route.path}?project_id=${payload}`);
        return;
      }
      try {
        this.toggleLoading("Loading data of Fixture Design");
        this.addHashToLocation(`?project_id=${payload}`);
        this.updateRedirect(`${this.$route.path}?project_id=${payload}`);
        await this.$store.dispatch("projects/fetchProject", payload);
        await Promise.all([
          this.$store.dispatch("fixturedesign/getFixtureDesign", {
            params: { project_id: payload },
          }),
          this.$store.dispatch("fixturedesign/getFixtureDesignState"),
          this.$store.dispatch("fixturedesign/getDesignFileOptions"),
        ]);
        this.toggleLoading();
        this.fdDetailsDialog = true;
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onCloseFDDetailsModal() {
      this.fdDetailsDialog = false;
      this.addHashToLocation("");
      this.updateRedirect(this.$route.path);
    },
    updateSearchTextField({ refname, payload }) {
      this.$refs[refname] && this.$refs[refname].updateSelection(payload);
    },
    async getDutConfigs(duts) {
      try {
        for (let i = 0; i < duts.length; i++) {
          await this.fetchDutConfigs(duts[i]);
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async unlinkQuoteToFD({ id, quoteId }) {
      console.log(id);
      this.quoteId = quoteId;
      this.showDialog({
        id,
        nextAction: "unlinkModal",
        message: "Are you sure you'd like to unlink this Quote?",
      });
    },
    openCompanyModal() {
      console.log("Company name is clicked!");
    },
    gotoFixtureDesignTable({ projectId, dutId }) {
      let routeData = this.$router.resolve({
        name: "configure",
        params: { id: projectId, did: dutId },
      });
      window.open(routeData.href, "_blank");
    },
  },
  async mounted() {
    try {
      this.toggleLoading("Loading Quote Data");
      await this.fetchStatus();
      this.toggleLoading();
      this.updateQuoteList();
    } catch (err) {
      this.toggleLoading();
    }
    this.active_tab = this.activeTab
      ? this.activeTab.name
      : this.DEFAULT_TAB.name;
    this.tab = this.activeTab ? this.activeTab.id : this.DEFAULT_TAB.id;
    if (this.$route.query.quote_id) {
      await this.quoteDetailsView(this.$route.query.quote_id);
    } else if (this.$route.query.project_id) {
      await this.onGoToFixtureDesign(this.$route.query.project_id);
    }
  },
};
</script>
<style scoped>
.admin-label /deep/ label {
  color: #950952 !important;
}
</style>